import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

const PreTransactionManagement = ScreenLoader(
  lazy(
    () => import("../screens/pretransaction-screen/PreTransactionManagement"),
  ),
);
const PreTransactionRequest = ScreenLoader(
  lazy(
    () =>
      import(
        "../screens/pretransactionrequest-screen/PreTransactionRequestManagement"
      ),
  ),
);
const ReconcileDeposit = ScreenLoader(
  lazy(() => import("../screens/reconcile-screen/ReconcileUserDeposit")),
);
const ReconcileWithdraw = ScreenLoader(
  lazy(() => import("../screens/reconcile-screen/ReconcileUserWithdraw")),
);

export const PreTransactionRoute = () => ({
  path: "pre-transaction",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "deposit-withdraw", element: <PreTransactionManagement /> },
    { path: "request", element: <PreTransactionRequest /> },
    { path: "reconcile-deposit", element: <ReconcileDeposit /> },
    { path: "reconcile-withdraw", element: <ReconcileWithdraw /> },
  ],
});
