import { useRoutes } from "react-router";
import { RootRoute } from "./RootRoute";
import { MemberRoute } from "./MemberRoute";
import { PreTransactionRoute } from "./PreTransactionRoute";
import { DefaultRoute } from "./DefaultRoute";
import { PromotionRoute } from "./PromotionRoute";
import { AffiliatePromotionRoute } from "./AffiliatePromotion";
import { RefundPromotionRoute } from "./RefundPromotionRoute";
import { MarketingRoute } from "./MarketingRoute";
import { ReconcileRoute } from "./ReconcileRoute";
import { AgentManagementRoute } from "./AgentManagementRoute";
import { EmployeeRoute } from "./EmployeeRoute";
import { AnnoucementRoute } from "./AnnouncementRoute";
import { ReportRoute } from "./ReportRoute";
import { SettingRoute } from "./SettingRoute";
import { WebsiteRoute } from "./WebsiteRoute";
import { DistribustorRoute } from "./DistribustorRoute";
import { CreditTrancsactionRoute } from "./CreditTrancsactionRoute";
import { DashboardRoute } from "./DashboardRoute";
import { BetReportRoute } from "./BetReportRoute";
import { PaymentGatewayRoute } from "./PaymentGatewayRoute";
import { PaymentAutoRoute } from "./PaymentAutoRoute";

export default function Router() {
  return useRoutes([
    RootRoute(),
    DefaultRoute(),
    DashboardRoute(),
    MemberRoute(),

    PreTransactionRoute(),
    ReconcileRoute(),

    PromotionRoute(),
    RefundPromotionRoute(),
    AffiliatePromotionRoute(),
    MarketingRoute(),

    AgentManagementRoute(),
    EmployeeRoute(),
    AnnoucementRoute(),

    ReportRoute(),
    CreditTrancsactionRoute(),
    BetReportRoute(),

    MarketingRoute(),
    SettingRoute(),
    PaymentGatewayRoute(),
    PaymentAutoRoute(),
    WebsiteRoute(),
    DistribustorRoute(),
  ]);
}
