import axios from "axios";
import { createContext, FC, useCallback, useEffect, useState } from "react";

interface IAuthContext {
  username: string;
  password: string;
  token: string;
  error: string;
  isProcessing: boolean;
  setUsername: (username: string) => void;
  setPassword: (password: string) => void;
  onAuthenticate: null | (() => Promise<boolean>);
  onReset: () => void;
  onLogout: () => void;
}
const authContextDefault: IAuthContext = {
  username: "",
  password: "",
  token: "",
  error: "",
  isProcessing: false,
  setUsername: (username: string) => { },
  setPassword: (password: string) => { },
  onAuthenticate: null,
  onReset: () => { },
  onLogout: () => { },
};
export const AuthContext = createContext(authContextDefault);
export const AuthContextProvider: FC<any> = ({ children }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('NAGA_ADMIN:token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const onAuthenticate = useCallback(async () => {
    setIsProcessing(true);
    setError("");
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/authorization/login`,
        {
          username,
          password,
        }
      );

      if (request.data.error) {
        setError(`ไม่สามารถเข้าระบบได้: ${request.data.error}`);
        setIsProcessing(false);
        return false;
      }

      localStorage.setItem("NAGA_ADMIN:token", request.data.data);
      setToken(request.data.data);
      setError("");
      setIsProcessing(false);
      return true;
    } catch (error) {
      setError("ไม่สามารถเข้าระบบได้");
      setIsProcessing(false);
      return false;
    }
  }, [username, password]);

  const onReset = useCallback(async () => {
    setToken("");
    setUsername("");
    setPassword("");
    setError("");
    setIsProcessing(false);
  }, []);

  const onLogout = () => {
    setUsername("");
    setPassword("");
    localStorage.removeItem('NAGA_ADMIN:token');
    return false
  };

  return (
    <AuthContext.Provider
      value={{
        username,
        password,
        token,
        error,
        isProcessing,
        setUsername: (value) => setUsername(value),
        setPassword: (value) => setPassword(value),
        onAuthenticate,
        onReset,
        onLogout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
